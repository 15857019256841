import React from 'react';
import * as styles from './blog.module.css';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Link } from 'gatsby';

const Blog = ({ date, title, description, slug, image }) => {
  return (
    <div className="col-sm-12 col-md-6 col-lg-4">
      <Link to={`/blogs/${slug}/`}>
        <div className={styles.slider_card}>
          <img placeholder="blurred" src={image} alt={title} />

          <div className={styles.slider_cardcontent}>
            <h3>{date}</h3>
            <h2>{title}</h2>
            <p>{description}</p>
            <span className={styles.Readd}>Lees meer</span>
            <span className={styles.Readd}>
              <KeyboardDoubleArrowRightIcon />
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Blog;
