import React from 'react';
import { graphql } from 'gatsby';


import slda from '../images/blank-card-cardboard.webp';

import * as styles from './posts.module.css';

import Blog from '../components/Blogs/Blog';

import Layout from '../components/Layout';

import BlogHead from '../components/Blogs/BlogHead/BlogHead';
import Pagination from './Pagination';

const AllPosts = ({ pageContext, data }) => {
  const { currentPage, numPages } = pageContext;


  const posts = data.allMdx.edges;

  return (
    <Layout location={'blogs'}>
      <BlogHead />
      <div className="container">
        <div className="row">
          {posts.map((post) => {
            return (
              <Blog
                key={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.slug
                    : ''
                }
                date={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.date
                    : ''
                }
                title={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.title
                    : ''
                }
                description={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.description
                    : ''
                }
                slug={
                  post.node.frontmatter.slug !== undefined
                    ? post.node.frontmatter.slug
                    : ''
                }
                image={
                  post.node.frontmatter.og_image.publicURL !== undefined
                  ? post.node.frontmatter.og_image.publicURL
                  : slda
                }
              />
            );
          })}
        </div>
      <Pagination numPages={numPages} currentPage={currentPage} styles={styles} />

      </div>
    </Layout>
  );
};

export default AllPosts;

export const pageQuery = graphql`
  query AllPostsQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            date
            slug
            title
            tags
            description
            og_image {
              publicURL
            }
          }
        }
      }
    }
  }
`;
