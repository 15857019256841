import React, { useEffect, useState } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import LanguageMenu from './LanguageMenu';

import { StaticImage } from "gatsby-plugin-image"


const Navbar = ({ location }) => {
  const { t } = useTranslation();
  const { originalPath } = useI18next();
  const context = React.useContext(I18nextContext);

  const [isOpen, setIsOpen] = useState(false);
  const [sticky, setSticky] = useState(false);

  const { pathname } = location !== undefined ? location : 'blogs';

  const links = [
    { id: 1, text: t('Links_Home', 'Home'), url: '/' },
    { id: 2, text: t('Links_Features', 'Features'), url: '/features/' },
    { id: 3, text: t('Links_Prijzen', 'Prijzen'), url: '/prices/' },
  ];

  if (context.language === 'nl')
    links.push({ id: 4, text: t('Links_Blog', 'Blog'), url: '/blogs/' });

  links.push({ id: 5, text: t('Links_Contact', 'Contact'), url: '/contact/' });

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleSticky = () => {
    if (window.scrollY > 80 && !sticky) {
      setSticky(true);
    }

    if (window.scrollY < 80 && sticky) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSticky);
  });
  return (
    <div className="container">
      <div className="absolute top-0 inset-x-0 z-3000">
        <div
          className={`${
            sticky
              ? 'fixed -top-24 inset-x-0 translate-y-24 bg-white nv-shadow onscrol-text'
              : `translate-y-0 text-white normal-nav`
          } transition-all ease-in z-3000  duration-300 w-full `}
        >
          <div className="w-full ">
            <div className=" container px-3 md:px-10 py-3  ">
              <div className="flex justify-between self-center items-center w-full ">
                <Link
                  to={`/`}
                  className="uppercase site-logo text-halingo text-20"
                >
                  Halingo
                </Link>
                <ul className=" desktop-button" style={{ marginBottom: '0px' }}>
                  {links.map((link) => {
                    const { id, text, url } = link;
                    return (
                      <li key={id} className="flex self-center  ">
                        <Link
                          to={url}
                          className={`${
                            sticky ? ' text-black' : ' text-white '
                          } ${
                            pathname === url ? 'onscrol-text' : ''
                          } w-full px-36 menu-nav-link`}
                          style={{ cursor: 'pointer' }}
                        >
                          <span style={{ cursor: 'pointer' }}>{text}</span>
                        </Link>
                      </li>
                    );
                  })}
                  <li className="flex self-center">
                    {!originalPath.includes('blog') ? (
                      <LanguageMenu sticky={sticky}  sidebar={false} pathname={pathname} />
                    ) : (
                      ''
                    )}
                  </li>
                </ul>
                <div className="button-container desktop-button">
                  <a
                    href="https://app.halingo.be/login"
                    className={`${
                      sticky ? ' sticky-btn-halingo' : 'btn-halingo '
                    }  capitalize `}
                  >
                    {t('Links_Button', 'Aanmelden')}
                  </a>
                </div>

                <div>
                  <span
                    className="text-28 mobileMenu"
                    onClick={toggleDrawer}
                    style={{ cursor: 'pointer' }}
                  >
                    <MenuIcon />
                  </span>
                  <Drawer open={isOpen} onClose={toggleDrawer} anchor="right">
                    <div className="h-full w-full pt-6 mb-10 px-4 pt-4">
                      <Link to="/">
                        <StaticImage src="../../images/logo_small.png" alt="Halingo small Logo" />
                      </Link>
                      <div className="pt-5">
                        {links.map((link) => {
                          const { id, text, url } = link;
                          return (
                            <span key={id} className="pb-3 block">
                              <Link to={url} className="capitalize d-block ">
                                {text}
                              </Link>
                            </span>
                          );
                        })}
                        <li className="flex self-center">
                          {!originalPath.includes('blog') ? (
                            <LanguageMenu sticky={sticky} sidebar={true} pathname={pathname} />
                          ) : (
                            ''
                          )}
                        </li>
                        <span className="pt-5 block">
                          <div className="button-container">
                            <a
                              href="https://app.halingo.be/login"
                              className={`${
                                sticky ? ' sticky-btn-halingo' : 'btn-halingo '
                              }  capitalize `}
                            >
                              {t('Links_Button', 'Aanmelden')}
                            </a>
                          </div>
                        </span>
                      </div>
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
