import React from "react";

const MeerVrije = ({ title, description, descriptionB }) => {
  return (
    <div>
      {" "}
      <div
        className="site-blocks-cover overlay meer-vrije"
        style={{ backgroundImage: "../../images/women_1.webp" }}
        id="freetime-section"
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12">
              <h2>
                <span className="text-halingo">{title}</span>
              </h2>
              <p className="mb-5" style={{ fontSize: "1.5em" }}>
                {description}
                <br />
                {descriptionB}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeerVrije;
