import React from 'react'
import { Link } from 'gatsby';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Pagination = ({ styles, numPages, currentPage }) => {
  if(numPages < 2){
    return (<div></div>);
  }

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/blogs' : '/blogs/' + (currentPage - 1).toString();
  const nextPage = '/blogs/' + (currentPage + 1).toString();
  const getPageNumberPath = (currentIndex) => {
    if (currentIndex === 0) {
      return '/blogs';
    }

    return '/blogs/' + (currentIndex + 1);
  };

  return (
      <div className={styles.paginationWrapper}>
          <Link
            className={
              isFirst ? styles.disabledButton : styles.nextPageClassName
            }
            to={prevPage}
            rel="prev"
          >
            <span>
              <ChevronLeftIcon className={styles.nextPrev} />
            </span>
          </Link>
          {Array.from({ length: numPages }, (_, i) => {
            let pageNumberClassName = styles.pageNumber;
            if (currentPage === i + 1) {
              pageNumberClassName = styles.currentPageNumber;
            }

            return (
              <Link key={i + 1} to={getPageNumberPath(i)}>
                <span
                  className={
                    pageNumberClassName
                      ? pageNumberClassName
                      : styles.paginationNumber
                  }
                >
                  {i + 1}
                </span>
              </Link>
            );
          })}
          <Link
            className={
              isLast ? styles.disabledButton : styles.nextPageClassName
            }
            to={nextPage}
            rel="next"
          >
            <span>
              <ChevronRightIcon className={styles.nextPrev} />
            </span>
          </Link>
        </div>
  )
}

export default Pagination
