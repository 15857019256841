import React from "react";
import { Link } from "gatsby";
const Popular = ({
  Prices_Popular_Title_A,
  Prices_Popular_Title_B,
  Prices_Popular_Title_C,
  Prices_Popular_keyA,
  Prices_Popular_Features_A,
  Prices_Popular_Features_B,
  Prices_Popular_Month,
  Prices_Popular_btn,
  Prices_Popular_Description,
  Prices_Popular_Terms,
  Prices_Popular_Link,
}) => {
  return (
    <div className="popular-wrap">
      <div className="site-blocks mb-sm-5" id="prices-section">
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5" />
          </div>
          <div className="row">
            <div className="col-12 mt-3 text-center">
              <div
                className="text-halingo"
                style={{ textAlign: "center", fontSize: "2em" }}
              >
                POPULAIR
              </div>
            </div>
          </div>
          <div
            className="row align-items-start justify-content-center"
          >
            <div className="col-md-4">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-halingo"
                    style={{ textAlign: "center", fontSize: "1em" }}
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 prices-container shadow px-5 pt-3 pb-5"
                style={{ zIndex: 5 }}>
                  <div className="icon icon-rocket" />
                  <div className="title">{Prices_Popular_Title_A}</div>
                  <div style={{ lineHeight: "2em" }} className="mt-4 mb-2">
                    <strong>5+</strong> {Prices_Popular_keyA}
                    <br />
                    {Prices_Popular_Features_A}
                    <br />
                    {Prices_Popular_Features_B}
                    <br />
                  </div>
                  <div className="dropdown-divider m-3" />
                  <div id="price_premium">
                    <span style={{ fontSize: "1em" }}>&nbsp;</span>
                    <br />
                    <span className="text-halingo" style={{ fontSize: "2em" }}>
                      <span style={{ fontSize: "0.7em" }}>€</span> 78.65
                    </span>
                    /{Prices_Popular_Month}
                  </div>
                  <a
                    href="https://app.halingo.be/register"
                    className="btn btn-outline-halingo py-2 px-3 d-block d-sm-inline-block mt-3"
                  >
                    {Prices_Popular_btn}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div
                  className="col-12 prices-container prices-container-active shadow px-5 pt-3 pb-5"
                  style={{ zIndex: 5 }}
                >
                  <div className="icon icon-paper-plane-o" />
                  <div className="title">{Prices_Popular_Title_B}</div>
                  <div style={{ lineHeight: "2em" }} className="mt-4 mb-2">
                    <strong>2-5</strong> {Prices_Popular_keyA}
                    <br />
                    {Prices_Popular_Features_A}
                    <br />
                    {Prices_Popular_Features_B}
                    <br />
                  </div>
                  <div className="dropdown-divider m-4" />
                  <div className="m-3" id="price_standard">
                    <span style={{ fontSize: "1em" }}>&nbsp;</span>
                    <br />
                    <span className="text-halingo" style={{ fontSize: "2em" }}>
                      <span style={{ fontSize: "0.7em" }}>€</span> 54.45
                    </span>
                    /{Prices_Popular_Month}
                  </div>
                  <a
                    href="https://app.halingo.be/register"
                    className="btn btn-outline-halingo py-2 px-3 d-block d-sm-inline-block mt-4"
                  >
                    {Prices_Popular_btn}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-halingo"
                    style={{ textAlign: "center", fontSize: "1em" }}
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 prices-container shadow px-5 pt-3 pb-5"
                style={{ zIndex: 5 }}>
                  <div className="icon icon-directions_bike" />
                  <div className="title">{Prices_Popular_Title_C}</div>
                  <div style={{ lineHeight: "2em" }} className="mt-4 mb-2">
                    <strong>1</strong> {Prices_Popular_keyA}
                    <br />
                    {Prices_Popular_Features_A}
                    <br />
                    {Prices_Popular_Features_B}
                    <br />
                  </div>
                  <div className="dropdown-divider m-3" />
                  <div id="price_basic">
                    <span style={{ fontSize: "1em" }}>&nbsp;</span>
                    <br />
                    <span className="text-halingo" style={{ fontSize: "2em" }}>
                      <span style={{ fontSize: "0.7em" }}>€</span> 24.2
                    </span>
                    /{Prices_Popular_Month}
                  </div>
                  <a
                    href="https://app.halingo.be/register"
                    className="btn btn-outline-halingo py-2 px-3 d-block d-sm-inline-block mt-3"
                  >
                    {Prices_Popular_btn}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 mt-5"
              style={{
                textAlign: "center",
                fontSize: "0.8em",
                lineHeight: "2em",
                zIndex: 5
              }}
            >
              <div className="price-disclaimer">
                {Prices_Popular_Description}
                <br />
                <Link to="/toc/">
                  {Prices_Popular_Terms}
                </Link>
                <br />
                <Link to="/gdpr/">
                  {Prices_Popular_Link}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popular;
